import { configureStore } from '@reduxjs/toolkit';
import pageSlice from './page-slice';
import notificationSlice from './notification-slice';
import subAppSlice from './subapp-slice';
import popUpModalSlice from './popUpModal-slice';


const store = configureStore({
    reducer: { 
        page: pageSlice.reducer,
        notification: notificationSlice.reducer,
        subApp: subAppSlice.reducer,
        popUpModal: popUpModalSlice.reducer
     },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
