//Message
export const SIGNIN_SUCCESS = 'You are successfully logged in!'
export const SIGNOUT_SUCCESS = 'You are successfully logged out!'
export const SIGNUP_SUCCESS = 'You are successfully created an account with us!'
export const CREATE_SUBAPP_SUCCESS = 'You are successfully created an subapp!'
export const CREATE_SUBAPP_FAILURE = 'Failed to created an subapp!'
export const UPDATE_SUBAPP_SUCCESS = 'Successfully updated an subapp!'
export const UPDATE_SUBAPP_FAILURE = 'Failed to update an subapp!'
export const DELETE_SUBAPP_SUCCESS = 'Successfully deleted an subapp!'
export const DELETE_SUBAPP_FAILURE = 'Failed to delete an subapp!'

