import { API } from "../config";

// Define the user type for clarity
export interface User {
  name: string;
  email: string;
  password: string;
  [key: string]: any; // If additional fields may exist
}

// Define the response type
interface SignupResponse {
  success: boolean;
  data: any | null;
  message: string;
}

export const userSignup = (user: User) => {
  return async (dispatch: any): Promise<SignupResponse> => {
    const signupRequest = async (): Promise<void> => {
      const response = await fetch(`${API}/signup`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (!response.ok) {
        throw new Error("Sending signup data failed!");
      }
    };

    try {
      await signupRequest();
      return { success: true, data: null, message: "Sign up successfully" };
    } catch (error) {
      console.error("Cannot sign up", error);
      return { success: false, data: null, message: "Sign up failed" };
    }
  };
};

//signin method
export const userSignin = (user:any) => {
  // return fetch(`${API}/signin`, {
  //   method: "POST",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(user),
  // })
  //   .then((response) => {
  //     return response.json();
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  return async (dispatch: any): Promise<SignupResponse> => {
    const signupRequest = async (): Promise<void> => {
      const response = await fetch(`${API}/signup`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (!response.ok) {
        throw new Error("Sending signup data failed!");
      }
    };

    try {
      await signupRequest();
      return { success: true, data: null, message: "Sign up successfully" };
    } catch (error) {
      console.error("Cannot sign up", error);
      return { success: false, data: null, message: "Sign up failed" };
    }
  };
};