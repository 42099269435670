import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { API } from "../../../config";
import { json, useLoaderData } from "react-router-dom";
import { ApiResponse } from "../../../helpers/common";

const CharacterPage = () => {
    const dispatch = useAppDispatch();
    const characters = (useLoaderData() as ApiResponse).data;
    useEffect(() => {
        // dispatch(pageActions.setCurrentPage(page))
    }, []);

    return (characters && <>
      <div>
        {JSON.stringify(characters)}
      </div>
    </>
        
    );
};

export default CharacterPage;

export async function characterLoader() {
    const response = await fetch(`${API}/dbl/characters`);
    if (!response.ok) {
      throw json({
        message: 'Could not fetch characters!'
      }, { status: 500 })
    } else {
      const resData = await response.json();
      return resData;
    }
  };