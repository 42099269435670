import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HOME_PAGE_NAME, HOME_PAGE_URL } from "../constants/page";

export interface pageState {
    currentPage: string,
    url: string,
}

const initialPageState: pageState = {
    currentPage: HOME_PAGE_NAME,
    url: HOME_PAGE_URL
};

const pageSlice = createSlice({
    name: 'page',
    initialState: initialPageState,
    reducers: {
        setCurrentPage(state, action: PayloadAction<pageState>) {
            state.currentPage=action.payload.currentPage
            state.url = action.payload.url
        }
    }
});

export const pageActions = pageSlice.actions;

export default pageSlice;