import  { Fragment, useEffect, useState } from "react";
import { signin, authenticate, isAuthenticated } from "../../../auth";
import { Button, Container } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { pageActions, pageState } from "../../../store/page-slice";
import { SIGNIN_PAGE_NAME, SIGNIN_PAGE_URL } from "../../../constants/page";
import { notificationActions } from "../../../store/notification-slice";
import { SIGNIN_SUCCESS } from "../../../constants/notification";

const Signin = () => {

    const [values, setValues] = useState({
        email: localStorage.getItem('userEmail') || '',
        password: "",
        loading: false,
        redirectToReferrer: false,
    });
    const [rememberMe, setRememberMe] = useState(localStorage.getItem('userEmail') ? true : false);
    const { email, password, loading, redirectToReferrer } = values;
    const { user } = isAuthenticated();



    const handleChange = (name: any) => (event: any) => {
        setValues({ ...values, [name]: event.target.value });
    };

    const dispatch = useAppDispatch();
    

    useEffect(() => {
        const page: pageState={currentPage:SIGNIN_PAGE_NAME, url:SIGNIN_PAGE_URL}
        dispatch(pageActions.setCurrentPage(page));
    }, [dispatch]);

    const clickSubmit = (event: any) => {
        event.preventDefault();
        setValues({ ...values, loading: true });
        signin({ email, password }).then(data => {
            if (data.error) {
                setValues({ ...values, loading: false });
                dispatch(notificationActions.enqueueNotification({message:"Username or password is incorrect", variant:"error"}));
            } else {
                dispatch(notificationActions.enqueueNotification({message:SIGNIN_SUCCESS, variant:"success"}));
                authenticate(data, () => {
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    });
                });
                // localStorage.username = "";
                if (rememberMe) {
                    localStorage.setItem('userEmail', email);
                } else {
                    localStorage.removeItem('userEmail');
                }

            }
        });

    };
    const signInForm = () => {
        return (
            <Container>
                <form>
                    <div className="form-group">
                        <label className="text-muted">Email</label>
                        <input
                            onChange={handleChange("email")}
                            type="email"
                            className="form-control"
                            value={email}
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">Password</label>
                        <input
                            onChange={handleChange("password")}
                            type="password"
                            className="form-control"
                            value={password}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
                        <Button onClick={clickSubmit}>
                            Submit
                        </Button>
                    </div>

                </form>
            </Container>
        );
    }

    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>Loading...</h2>
            </div>
        );

    const redirectUser = () => {
        if (redirectToReferrer) {
            if (user && user.role === 1) {
                return <Navigate to='/admin/dashboard' />
            } else {
                return <Navigate to="/user/dashboard" />;
            }
        }
        if (isAuthenticated()) {
            return <Navigate to="/" />;
        }
    };
    return (
        <Fragment>
            {showLoading()}
            {signInForm()}
            {redirectUser()}
        </Fragment>
    );
};

export default Signin;