import { ApiResponse } from "../helpers/common";
import { API } from "../config";

export const createSubApp = (userId: any, token: any, subApp: any) => {
    return async (): Promise<ApiResponse> => {
        try {
            const response = await fetch(`${API}/subApp/create/${userId}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: subApp
            });
            if (!response.ok) {
                throw new Error(`E!`);
            }
            return response.json();
        } catch (error: any) {
            return { success: false, message: 'Failed to create Sub Apps!', data: null };
        }
    };
};

export const updateSubApp = (subAppId: string, userId: any, token: any, subApp: any) => {
    return async (): Promise<ApiResponse> => {
        try {
            // console.log('subapp: ', subApp);
            // let subAppId = subApp._id;
            const response = await fetch(`${API}/subApp/${subAppId}/${userId}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: subApp
            });
            if (!response.ok) {
                throw new Error(`E!`);
            }
            return response.json();
        } catch (error: any) {
            return { success: false, message: 'Failed to update Sub Apps!', data: null };
        }
    };
};

export const deleteSubApp = (subAppId: any, userId: any, token: any) => {
    return async (): Promise<ApiResponse> => {
        try {
            const response = await fetch(`${API}/subApp/${subAppId}/${userId}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Deleting Error!`);
            }
            return response.json();
        } catch (error: any) {
            return { success: false, message: 'Failed to create Sub Apps!', data: null };
        }
    };
};
