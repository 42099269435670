import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SubApp from "../models/subapp";

export interface subAppState {
    subApps: SubApp[];
}

const initialSubAppState: subAppState = {
    subApps: []
};

const subAppSlice = createSlice({
    name: 'subApps',
    initialState: initialSubAppState,
    reducers: {
        setSubApps(state, action: PayloadAction<SubApp[]>) {
            state.subApps = action.payload;
        },
        addNewSubApp(state, action: PayloadAction<SubApp>) {
            const newApp = action.payload;
            state.subApps.push(newApp);
        },
        updateSubApp(state, action: PayloadAction<SubApp>) {
            const updatedApp = action.payload;
            state.subApps = state.subApps.map(app=>app._id===updatedApp._id? {...app, ...updatedApp}:app)
        },
        removeSubApp(state, action: PayloadAction<string>) {
            const id = action.payload;
            state.subApps = state.subApps.filter((app) => app._id !== id);
        },
    }
});

export const subAppsActions = subAppSlice.actions;

export default subAppSlice;