import { closeSnackbar, SnackbarProvider, useSnackbar } from "notistack";
import Menu from "../Menu";
import { json, Outlet, useLoaderData } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { notificationActions } from "../../store/notification-slice";
import { useAppDispatch } from "../../app/hooks";
import { API } from "../../config";
import { ApiResponse } from "../../helpers/common";
import { subAppsActions } from "../../store/subapp-slice";

const NotiStack = () => {

  const notifications = useSelector((state: RootState) => state.notification);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.queue.map((notification) => {
      //use if only notification without no close action
      //enqueueSnackbar("I love snacks.");
      enqueueSnackbar(notification.message, {
        variant:notification.variant, persist: true,
        action: (key: any) => (
          <div onClick={() => closeSnackbar(key)}><ClearIcon /></div>
        ),
      });
    });

    if (notifications.queue.length > 0) {
      dispatch(notificationActions.clearNotifications());
    }
  }, [notifications, enqueueSnackbar, dispatch]);


  return (
    <div>
    </div>
  );
};

function RootLayout() {

  const subApps = (useLoaderData() as ApiResponse).data;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(subAppsActions.setSubApps(subApps));
  }, [dispatch, subApps]);

  return <>
    <SnackbarProvider maxSnack={5}>
      <Menu />
      {/* {JSON.stringify(subApps)} */}
      <main>
        <Outlet />
      </main>
      <NotiStack />
    </SnackbarProvider>
  </>
}

export default RootLayout;

export async function initLoader() {
  const response = await fetch(`${API}/subApps`);
  if (!response.ok) {
    throw json({
      message: 'Could not fetch Sub Apps!'
    }, { status: 500 })
  } else {
    const resData = await response.json();
    return resData;
  }
};