//page name
export const HOME_PAGE_NAME = 'HOME'
export const SIGNIN_PAGE_NAME = 'SIGNIN'
export const SIGNUP_PAGE_NAME = 'SIGNUP'
export const ADMIN_DASHBOARD_PAGE_NAME = 'ADMIN_DASHBOARD'
export const USER_DASHBOARD_PAGE_NAME = 'USER_DASHBOARD'
export const ERROR_PAGE_NAME = 'ERROR'
export const FINANCE_APP_PAGE_NAME = 'FINANCE_APP'

//page url 
export const HOME_PAGE_URL = '/'
export const SIGNIN_PAGE_URL = '/signin'
export const SIGNUP_PAGE_URL = '/signup'
export const ADMIN_DASHBOARD_PAGE_URL = '/admin/dashboard'
export const USER_DASHBOARD_PAGE_URL = '/user/dashboard'
export const FINANCE_APP_PAGE_URL = '/user/finance-app'