import { Container, Navbar, Nav } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { notificationActions } from "../store/notification-slice";
import { SIGNOUT_SUCCESS } from "../constants/notification";
import { isAuthenticated, signout } from "../auth";

const Menu = () => {

  let navigate = useNavigate();

  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleSignout = () => {
    signout(() => {});
    dispatch(notificationActions.enqueueNotification({message:SIGNOUT_SUCCESS, variant:"success"}));
    navigate('/signin');
  }
  const isActive = (path: any, navDropDown = false) => {
    if (location.pathname === path) {
      return { color: "#a67814" };
    } else {
      if (navDropDown) {
        return { color: "#181a19" };
      } else {
        return { color: "#e3e0da" };
      }
    }
  };
  return (
    <Navbar
      bg="dark"
      expand="lg"
      data-bs-theme="dark"
      className="bg-body-tertiary"
    >
      <Container fluid>
        <Navbar.Brand href="/">LN</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto">
            <Nav.Link href="/" style={isActive("/")} >
              Home
            </Nav.Link>
            {isAuthenticated() && isAuthenticated().user.role === 0 && (
              <Nav.Link
                href="/user/dashboard"
                style={isActive("/user/dashboard")}
              >
                Dash Board
              </Nav.Link>
            )}
            {isAuthenticated() && isAuthenticated().user.role === 1 && (
              <Nav.Link
                href="/admin/dashboard"
                style={isActive("/admin/dashboard")}
              >
                Dash Board
              </Nav.Link>
            )}
            {!isAuthenticated() && (
              <>
                <Nav.Link href="/signin" style={isActive("/signin")}>
                  SignIn
                </Nav.Link>
                <Nav.Link href="/signup" style={isActive("/signup")}>
                  SignUp
                </Nav.Link>
              </>
            )}

            {isAuthenticated() && (
              <>
                <Nav.Link
                  onClick={handleSignout}
                >
                  Sign Out
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
