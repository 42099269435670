import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from ".";

interface AdminRouteProps {
  children: ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const auth = isAuthenticated();

  if (auth && auth.user?.role === 1) {
    return <>{children}</>;
  }
  return <Navigate to="/signin" replace />;
};

export default AdminRoute;