import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from ".";

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  if (isAuthenticated()) {
    return <>{children}</>;
  }
  return <Navigate to="/signin" replace />;
};

export default PrivateRoute;