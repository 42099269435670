import { useAppDispatch } from "../../../app/hooks";

const DblHomePage = () => {
    const dispatch = useAppDispatch();

    return (
        <div style={{ alignItems: 'center' }}>
            <h3 >Welcome to Dragon Ball Legends Analysis App</h3>
            
        </div>
    );
};

export default DblHomePage;