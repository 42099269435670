import { useEffect } from "react";
import { isAuthenticated } from "../../../auth";
import { Link } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';
import { useAppDispatch } from "../../../app/hooks";
import { USER_DASHBOARD_PAGE_NAME, USER_DASHBOARD_PAGE_URL } from "../../../constants/page";
import { pageActions, pageState } from "../../../store/page-slice";

const UserDashBoard = () => {

    const {
        user: { _id, name, email, role }
    } = isAuthenticated();

    // const token = isAuthenticated().token;

    const dispatch = useAppDispatch();

    useEffect(() => {
        const page: pageState = { currentPage: USER_DASHBOARD_PAGE_NAME, url: USER_DASHBOARD_PAGE_URL }
        dispatch(pageActions.setCurrentPage(page));
    }, [dispatch]);

    const userInfo = () => {
        return (
            <div className="card mb-5">
                <h3 className="card-header">User Information</h3>
                <ul className="list-group">
                    <li className="list-group-item">{name}</li>
                    <li className="list-group-item">{email}</li>
                    <li className="list-group-item">
                        {role === 1 ? "Admin" : "Registered User"}
                    </li>
                </ul>
            </div>
        );
    };

    const userLinks = () => {
        return (
            <div className="card"
                style={{ marginBottom: "1vh" }}
            >
                <h4 className="card-header">User Links</h4>
                <ul className="list-group">
                    <li className="list-group-item">
                        <Link className="nav-link" to="/cart">
                            My Cart
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/profile/${_id}`}>
                            Update Profile
                        </Link>
                    </li>
                </ul>
            </div>
        );
    };


    return (

        <Container fluid="xl">
            {
                <Row >
                    <Col lg={2}>
                        {userLinks()}
                    </Col>
                    <Col lg={10}>
                        {userInfo()}
                    </Col>
                </Row>

            }
        </Container>

    );
};

export default UserDashBoard;