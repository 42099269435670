import { createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Finance from "./pages/Finance";
import RootLayout, { initLoader } from "./pages/RootLayout";
import UserDashBoard from "./pages/user/UserDashBoard";
import ErrorPage from "./pages/ErrorPage";
import Signin from "./pages/user/Signin";
import Signup from "./pages/user/Signup";
import PrivateRoute from "../auth/PrivateRoute";
import AdminRoute from "../auth/AdminRoute";
import AdminDashBoard from "./pages/user/AdminDashBoard";
import ManageSubApps from "./pages/admin/ManageSubApps";
import DblHomePage from "./pages/dragonBallLegends/DblHomePage";
import CharacterPage, { characterLoader } from "./pages/dragonBallLegends/CharacterPage";

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        errorElement: <ErrorPage />,
        loader: initLoader,
        children: [
            {
                index: true, element: <HomePage />,
            },//path:''
            { path: '/signin', element: <Signin /> },
            { path: '/signup', element: <Signup /> },
            {
                path: '/admin/manage-subapps',
                element: <AdminRoute><ManageSubApps /></AdminRoute>
            },
            { path: '/admin/dashboard', element: <AdminRoute><AdminDashBoard /></AdminRoute> },
            { path: '/user/dashboard', element: <PrivateRoute><UserDashBoard /></PrivateRoute> },
            { path: '/user/finance-app', element: <PrivateRoute><Finance /></PrivateRoute> },
            { path: '/user/dbl-app', element: <PrivateRoute><DblHomePage /></PrivateRoute> },
            { path: '/user/dbl-app/characters', element: <PrivateRoute><CharacterPage /></PrivateRoute>, loader: characterLoader }
        ]
    },

],
    {
        future: {
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_fetcherPersist: true,
            v7_skipActionErrorRevalidation: true,
            v7_relativeSplatPath: true,
        },
    }
)

export default router;