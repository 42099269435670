import { useRouteError } from "react-router-dom";
import Menu from "../Menu";
import PageContent from "./PageContent";

function ErrorPage(){
    const error:any = useRouteError();

    let title = 'An error occurred!';
    let message = 'Something went wrong!';

    if(error.status===500){
        message = error.data.message;
    }

    if(error.status===404){
        title = ' Not found'
        message = 'Could not find resource or page.';
    }

    return (
        <>
        <Menu />
        <main>
           <PageContent title={title}>
            <p>{message}</p>
           </PageContent>
        </main>
        </>
    )
}

export default ErrorPage;