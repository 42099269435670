import { useEffect, useState } from "react";
import { isAuthenticated } from "../../../auth";
import { Link } from "react-router-dom";
import { Container, Row, Col, Collapse, ListGroup, Card } from 'react-bootstrap';
import { useAppDispatch } from "../../../app/hooks";
import { pageActions, pageState } from "../../../store/page-slice";
import { ADMIN_DASHBOARD_PAGE_NAME, ADMIN_DASHBOARD_PAGE_URL } from "../../../constants/page";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const page: pageState = { currentPage: ADMIN_DASHBOARD_PAGE_NAME, url: ADMIN_DASHBOARD_PAGE_URL }

export const AdminLinks = () => {
    const [adminLinkOpen, setAdminLinkOpen] = useState(false);
    return (
        <Row>
            <Col>
                <button
                    onClick={() => setAdminLinkOpen(!adminLinkOpen)}
                    aria-controls="description-collapse-text"
                    aria-expanded={adminLinkOpen}
                    className='dropdownContentButton'
                    style={{ background: '#b5b38a' }}
                >
                    <Row>
                        <Col className="d-flex justify-content-center" >
                            Admin_Link
                            {
                                adminLinkOpen ?
                                    <RiArrowDropUpLine style={{ fontSize: '25px', marginLeft: '2vw' }} />
                                    :
                                    <RiArrowDropDownLine style={{ fontSize: '25px', marginLeft: '2vw' }} />
                            }
                        </Col>
                    </Row>
                </button>
            </Col>
            <Collapse in={adminLinkOpen} >
                <div id="description-collapse-text" style={{ textAlign: 'center', margin: '1vw' }}>
                    <ListGroup >
                        <ListGroup.Item className='d-flex justify-content-center'>
                            <Link to="/admin/manage-subapps">
                                Manage Sub Apps
                            </Link>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center'>
                            <Link to="/create/bead">
                                Create Bead
                            </Link>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center'>
                            <Link to="/admin/orders">
                                View Orders
                            </Link>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center'>
                            <Link to="/admin/products">
                                Manage Products
                            </Link>
                        </ListGroup.Item>

                        <ListGroup.Item className='d-flex justify-content-center' >
                            <Link to="/admin/database">
                                Manage Database
                            </Link>
                        </ListGroup.Item>
                    </ListGroup>
                </div>
            </Collapse>
        </Row>
    );
};

const AdminDashBoard = () => {

    const {
        user: { name, email, role }
    } = isAuthenticated();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(pageActions.setCurrentPage(page));
    }, [dispatch]);

    const adminInfo = () => {
        return (
            <Card>
                <h3 className="card-header">User Information</h3>
                <ul className="list-group">
                    <li className="list-group-item">{name}</li>
                    <li className="list-group-item">{email}</li>
                    <li className="list-group-item">
                        {role === 1 ? "Admin" : "Registered User"}
                    </li>
                </ul>
            </Card>
        );
    };

    return (
        <Container fluid="xl" style={{margin: '2px'}}>
            {
                <Row >
                    <Col lg={2}>
                        {AdminLinks()}
                    </Col>
                    <Col lg={10}>
                        {adminInfo()}
                    </Col>
                </Row>
            }
        </Container>
    );
};

export default AdminDashBoard;