import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface popUpModalState {
    show: boolean;
    actionType: string;
    actionItem: any;
}

const initialPopUpModalState: popUpModalState = {
    show: false,
    actionType: '',
    actionItem: {}
};

const popUpModalSlice = createSlice({
    name: 'popUpModal',
    initialState: initialPopUpModalState,
    reducers: {
        onAddShow(state){
            state.show=true;
            state.actionType='Add';
        },
        onclose(state){
            state.show=false;
            state.actionType='';
        },
        onUpdateShow(state, action){
            state.show=true;
            state.actionType='Update';
            state.actionItem = action.payload;
        },
        onDeleteShow(state, action){
            state.show=true;
            state.actionType='Delete';
            state.actionItem = action.payload;
        },
        // setSubApps(state, action: PayloadAction<SubApp[]>) {
        //     state.subApps = action.payload;
        // },
        // addNewSubApp(state, action: PayloadAction<SubApp>) {
        //     const newApp = action.payload;
        //     state.subApps.push(newApp);
        // },
        // updateSubApp(state, action: PayloadAction<SubApp>) {
        //     const updatedApp = action.payload;
        //     state.subApps = state.subApps.map(app => app._id === updatedApp._id ? { ...app, ...updatedApp } : app)
        // },
        // removeSubApp(state, action: PayloadAction<string>) {
        //     const id = action.payload;
        //     state.subApps = state.subApps.filter((app) => app._id !== id);
        // },
    }
});

export const popUpModalActions = popUpModalSlice.actions;

export default popUpModalSlice;