// HomePage.js
import React, { Fragment, useEffect } from "react";
import EnhancedTable from "./charts/EnhancedTable";
import LineChart from "./charts/LineChart";
import { useAppDispatch } from "../../app/hooks";
import { pageActions, pageState } from "../../store/page-slice";
import { FINANCE_APP_PAGE_NAME, FINANCE_APP_PAGE_URL } from "../../constants/page";
// import LineChart from "./charts/LineChart";


const Finance = () => {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        const page: pageState={currentPage:FINANCE_APP_PAGE_NAME, url:FINANCE_APP_PAGE_URL}
        dispatch(pageActions.setCurrentPage(page))
    }, [dispatch]);
    const labels = ['January', 'February', 'March', 'April', 'May', 'June'];
    const dataPoints = [65, 59, 80, 81, 56, 55];
    return (
        <Fragment>
            <EnhancedTable />
            <h1>Line Chart Example</h1>
            <LineChart labels={labels} dataPoints={dataPoints} />
        </Fragment>
    );
};

export default Finance;
