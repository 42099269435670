import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import { userSignup } from "../../../redux/user-actions";
import { pageActions, pageState } from "../../../store/page-slice";
import { SIGNUP_PAGE_NAME, SIGNUP_PAGE_URL } from "../../../constants/page";
import { notificationActions } from "../../../store/notification-slice";
import { SIGNUP_SUCCESS } from "../../../constants/notification";
const Signup = () => {
    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        success: false,
    });
    const dispatch = useAppDispatch();
    const { name, email, password, confirmPassword, success } = values;
    
    useEffect(() => {
        const page: pageState={currentPage:SIGNUP_PAGE_NAME, url:SIGNUP_PAGE_URL}
        dispatch(pageActions.setCurrentPage(page))
    }, [dispatch]);

    //handle change of the input
    const handleChange = (name: any) => (event: any) => {
        setValues({ ...values, [name]: event.target.value });
    };

    const clickSubmit = async (event: any) => {
        event.preventDefault();
        setValues({ ...values });
        if (confirmPassword === password) {
            dispatch(userSignup({ name, email, password })).then((res: any) => {
                if (res.success) {
                    dispatch(notificationActions.enqueueNotification({message:SIGNUP_SUCCESS, variant:"success"}));
                    setValues({
                        ...values,
                        name: "",
                        email: "",
                        password: "",
                        confirmPassword: "",
                        success: true,
                    });
                } else {
                    dispatch(notificationActions.enqueueNotification({message:res.message, variant:"error"}));
                    setValues({ ...values, success: false });
                }
            });
        } else {
            dispatch(notificationActions.enqueueNotification({message:"Password and Confirm Password do not match", variant:"error"}));
            setValues({
                ...values,
                success: false,
            });
        }
    };

    const signUpForm = () => (
        <form>
            <div className="form-group">
                <label className="text-muted">Name</label>
                <input
                    onChange={handleChange("name")}
                    type="text"
                    className="form-control"
                    value={name}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Email</label>
                <input
                    onChange={handleChange("email")}
                    type="email"
                    className="form-control"
                    value={email}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Password</label>
                <input
                    onChange={handleChange("password")}
                    type="password"
                    className="form-control"
                    value={password}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Confirm Password</label>
                <input
                    onChange={handleChange("confirmPassword")}
                    type="password"
                    className="form-control"
                    value={confirmPassword}
                />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                    onClick={clickSubmit}
                    className="btn btn-primary"
                    style={{ margin: "5px", width: "100px" }}
                >
                    Submit
                </button>
            </div>
        </form>
    );

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: success ? "" : "none" }}
        >
            New account is created. Please <Link to="/signin">Signin</Link>
        </div>
    );
    return (
        <Fragment>
            <div className="center-item">
                <div
                    style={{
                        width: "60vw",
                        maxWidth: "400px",
                        minWidth: "200px",
                    }}
                >
                    {showSuccess()}
                    {signUpForm()}
                </div>
            </div>
        </Fragment>
    );
};
export default Signup;
