import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { isAuthenticated } from "../../auth";
import { createSubApp, deleteSubApp, updateSubApp } from "../../redux/subapp-actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { notificationActions } from "../../store/notification-slice";
import { CREATE_SUBAPP_FAILURE, CREATE_SUBAPP_SUCCESS, DELETE_SUBAPP_FAILURE, DELETE_SUBAPP_SUCCESS, UPDATE_SUBAPP_FAILURE, UPDATE_SUBAPP_SUCCESS } from "../../constants/notification";
import { subAppsActions } from "../../store/subapp-slice";
import SubApp from "../../models/subapp";
import { useState } from "react";
import { popUpModalActions } from "../../store/popUpModal-slice";


const PopUpModal = () => {
    const { user, token } = isAuthenticated();
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const popUpModal = useAppSelector((state) => state.popUpModal);
    const [toolTipError, setToolTipError] = useState(false);
    const [appName, setAppName] = useState("");

    const [values, setValues] = useState({
        name: '',
        description: '',
        path: '',
        photo: '',
        formData: new FormData(),
    });
    const {
        name,
        description,
        path,
        formData,
    } = values;

    const removeSubApp = (subAppId: string) => {
        dispatch(deleteSubApp(subAppId, user._id, token)).then((res: any) => {
            if (res.success) {
                dispatch(subAppsActions.removeSubApp(subAppId));
                dispatch(notificationActions.enqueueNotification({ message: DELETE_SUBAPP_SUCCESS, variant: 'success' }));
            } else {
                dispatch(notificationActions.enqueueNotification({ message: DELETE_SUBAPP_FAILURE, variant: 'error' }));
            }
        });
    };

    const handleOnClose = () => {
        dispatch(popUpModalActions.onclose());
        setToolTipError(false);
        setAppName('');
        setValues({
            name: '',
            description: '',
            path: '',
            photo: '',
            formData: new FormData()
        });
    }

    const handleChange = (e: any) => {
        if (popUpModal.actionType === 'Delete') {
            setAppName(e.target.value);
            setToolTipError(false);
        }
        else {
            const { name, value } = e.target;
            const temp = { ...values };
            switch (name) {
                case "name":
                    temp.name = value;
                    formData.append("name", value);
                    break;
                case "description":
                    temp.description = value;
                    formData.append("description", value);
                    break;
                case "path":
                    temp.path = value;
                    formData.append("path", value);
                    break;
                case "photo":
                    temp.photo = e.target.files[0];
                    formData.append("photo", e.target.files[0]);
                    break;
                default:
                    break;
            }
            setValues(temp);
        }

    };
    const clickSubmit = (e: any) => {
        e.preventDefault();
        switch (popUpModal.actionType) {
            case "Add":
                dispatch(createSubApp(user._id, token, formData)).then((res: any) => {
                    if (res.success) {
                        let newSubApp = new SubApp(name, description, path);
                        //update locally:
                        dispatch(subAppsActions.addNewSubApp(newSubApp));
                        dispatch(notificationActions.enqueueNotification({ message: CREATE_SUBAPP_SUCCESS, variant: "success" }));
                        setValues({
                            name: '',
                            description: '',
                            path:'',
                            photo: '',
                            formData: new FormData()
                        });
                        //refresh to have image
                        navigate('#');
                    } else {
                        dispatch(notificationActions.enqueueNotification({ message: CREATE_SUBAPP_FAILURE, variant: 'error' }));
                    }
                });
                handleOnClose();
                break;
            case "Update":
                dispatch(updateSubApp(popUpModal.actionItem._id, user._id, token, formData)).then((res: any) => {
                    if (res.success) {
                        let temp: SubApp = { _id: popUpModal.actionItem._id, name: name, description: description,path: path, createdAt: popUpModal.actionItem.createdAt, updatedAt: popUpModal.actionItem.updatedAt };
                        dispatch(notificationActions.enqueueNotification({ message: UPDATE_SUBAPP_SUCCESS, variant: 'success' }));
                        dispatch(subAppsActions.updateSubApp(temp));
                        setValues({
                            name: '',
                            description: '',
                            path:'',
                            photo: '',
                            formData: new FormData()
                        });
                        navigate('#');
                    } else {
                        dispatch(notificationActions.enqueueNotification({ message: UPDATE_SUBAPP_FAILURE, variant: 'error' }));
                    }
                });
                handleOnClose();
                break;
            case "Delete":
                if (appName === popUpModal.actionItem.name) {
                    removeSubApp(popUpModal.actionItem._id);
                    handleOnClose();
                }
                else {
                    setToolTipError(true);
                }
                break;
            default:
                break;
        }
    }

    return (popUpModal.actionType === 'Delete' ? <>
        <Modal show={popUpModal.show} onHide={handleOnClose} >
            <Modal.Header closeButton>
                <Modal.Title>Delete Confimation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Please type "<b>{popUpModal.actionItem.name}</b>"  and hit confirm to delete
                </p>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        {<OverlayTrigger show={toolTipError}
                            placement="top"
                            overlay={
                                <Tooltip>Error! Name does not match. Please retype "{popUpModal.actionItem.name}" again!</Tooltip>}
                        >
                            <Form.Control
                                type="text"
                                name="appName"
                                onChange={(e) => handleChange(e)}
                                value={appName}
                                required={true}
                            />
                        </OverlayTrigger>
                        }
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleOnClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={clickSubmit}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    </> :
        <>
            <Modal
                show={popUpModal.show}
                onHide={handleOnClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{popUpModal.actionType} Sub App</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Post Photo</Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Please Select File"
                                autoFocus
                                onChange={(e) => handleChange(e)}
                                name="photo"
                                accept="image/*"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>App Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                onChange={(e) => handleChange(e)}
                                value={name}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                onChange={(e) => handleChange(e)}
                                value={description}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Path</Form.Label>
                            <Form.Control
                                type="text"
                                name="path"
                                onChange={(e) => handleChange(e)}
                                value={path}
                                required
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={handleOnClose}
                    >
                        Close
                    </Button>
                    <Button variant="primary"
                        onClick={clickSubmit}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PopUpModal;