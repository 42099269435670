// HomePage.js
import { useEffect } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import classes from '../../styles/Card.module.css';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { pageActions, pageState } from "../../store/page-slice";
import { HOME_PAGE_NAME, HOME_PAGE_URL } from "../../constants/page";
import { useLoaderData } from "react-router-dom";
import { ApiResponse } from "../../helpers/common";
import { CustomImage } from "../core/CustomImage";

const page: pageState = { currentPage: HOME_PAGE_NAME, url: HOME_PAGE_URL }
const HomePage = () => {
  const dispatch = useAppDispatch();
  const subApps = useAppSelector((state) => state.subApp.subApps);
  //record currentpage
  useEffect(() => {
    dispatch(pageActions.setCurrentPage(page))
  }, [dispatch]);

  const getImageStyle = () =>{
    return {width:'100%',height:'100%' }
  }

  return (
    <>
      {
        <Row className="justify-content-md-center">
          {subApps.map((col: any, col_idx: number) => {
            return <Col xs md="auto" key={col_idx}>
              <Card className={classes.card}>

                <CustomImage item={subApps[col_idx]} url='subApp' imageStyle={getImageStyle()} />

                <Card.Body>
                  <Card.Title>{subApps[col_idx].name}</Card.Title>
                  <Card.Text>
                    {subApps[col_idx].description}
                  </Card.Text>
                  <Button variant="primary">
                    <Nav.Link 
                    href={`/user/${subApps[col_idx].path}`}
                    >Use the App</Nav.Link>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          })}
        </Row>
      }

    </>

  );
};

export default HomePage;
