import { API } from "../config";

//signup method
export const signup = (user:any) => {
  return fetch(`${API}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

//signin method
export const signin = (user:any) => {
  return fetch(`${API}/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

//authenticate method
export const authenticate = (data:any, next:any) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};

//signout method
export const signout = (next:any) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");

    console.log("signing out...");
    return fetch(`${API}/signout`, {
      method: "GET",
    })
      .then((response) => {
        console.log("signout", response);
        return response.json();
      })
      .catch((err) => console.log(err));
  }
  next();
};

//isAuthenticated method
export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    let st = localStorage.getItem("jwt");
    if(st!=null){
      return JSON.parse(st);
    }
    
  } else {
    return false;
  }
};
