export const displayColsConverter = (colsName: string) => {
    switch (colsName) {
        case "name":
            return "Name";
        case "image":
            return "Image";
        case "description":
            return "Description";
        case "path":
            return "Path";
        case "createdAt":
            return "Created At";
        case "updatedAt":
            return "Updated At";
        default:
            return colsName;
    }
};
