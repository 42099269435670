class SubApp {
    _id: string;
    name: string;
    description: string;
    path:string;
    createdAt: string;
    updatedAt: string;
  
    constructor(name: string, description:string, path:string) {
      this._id = "";
      this.name = name;
      this.description = description;
      this.path = path;
      this.createdAt = '';
      this.updatedAt = '';
    }
  
    static getCols(tableCols: SubAppTableCols): string[] {
      return Object.getOwnPropertyNames(tableCols);
    }
  }
  
  export class SubAppTableCols {
    _id: boolean = false;
    name: boolean = true;
    description: boolean = true;
    path: boolean = true;
    createdAt: boolean = true;
    updatedAt: boolean = true;
  }
  
  export default SubApp;