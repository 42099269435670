import React, { ReactNode } from 'react';
import classes from '../../styles/PageContent.module.css';

// Define a type for the component's props
interface PageContentProps {
  title: string;
  children: ReactNode;
}

const PageContent: React.FC<PageContentProps> = ({ title, children }) => {
  return (
    <div className={classes.content}>
      <h1>{title}</h1>
      {children}
    </div>
  );
};

export default PageContent;