import { Button, Container, Table } from "react-bootstrap";
import SubApp, { SubAppTableCols } from "../../../models/subapp";
import { useState } from "react";
import { displayColsConverter } from "../../../helpers/converter";
import { API } from "../../../config";
import { json, Link } from "react-router-dom";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { CustomImage } from "../../core/CustomImage";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import PopUpModal from "../../core/PopUpModal";
import { popUpModalActions } from "../../../store/popUpModal-slice";

const ManageSubApps = () => {

  const [editStatus, setEditStatus] = useState(false);
  const dispatch = useAppDispatch();
  const subApps = useAppSelector((state) => state.subApp.subApps);

  const getTableCols = (): string[] => {
    const temp: string[] = [];
    const a: any = new SubAppTableCols();
    temp.push("image");
    SubApp.getCols(a).forEach((col: string) => {
      if (a[col]) {
        temp.push(col);
      }
    });
    return temp;
  };

  const getImageStyle = () => {
    return { width: '100%', height: '100%' }
  }


  const editCell = (colsName: string, row_idx: number, col_idx: number) => {
    switch (colsName) {
      case "image":
        return (
          <td key={col_idx}>
            <div className="center-item">
              <CustomImage item={subApps[row_idx]} url='subApp' imageStyle={getImageStyle()} />
            </div>
          </td>
        );
      case "name":
        return (
          <td key={col_idx}>
            <div className="center-item">
              <input
                value={subApps[row_idx][colsName]}
                name={colsName}
                disabled={true}
              />
            </div>
          </td>
        );
      case "description":
      case "path":
      case "createdAt":
      case "updatedAt":
        return (subApps[row_idx][colsName] &&
          <td key={col_idx}>
            <div className="center-item">
              <strong>{subApps[row_idx][colsName].toString().substring(0, 10)}</strong>
            </div>
          </td>
        );

      default:
        return null;
    }
  };
  return <>
    <Container fluid='xl'>
      <div>
        {(<>
          <Button
            size="sm"
            style={{ margin: "3px" }}
            onClick={() => {
              setEditStatus(!editStatus);
            }}
            variant="info"
          >
            Edit Mode Status: {editStatus ? "ON" : "OFF"}
          </Button>{" "}
          {editStatus && (
            <div className="center-item">
              <Button
                size="sm"
                style={{ margin: "3px" }}
                onClick={() => {
                  dispatch(popUpModalActions.onAddShow());
                }}
              >
                Create New Sub App
              </Button>
            </div>
          )}
        </>
        )}
        <PopUpModal />
        <Table striped bordered hover>
          <thead>
            <tr>
              {getTableCols().map((col, i) => {
                return (
                  <th key={i}>
                    <div className="center-item">
                      {displayColsConverter(col)}
                    </div>
                  </th>
                );
              })}
              {editStatus && (
                <th>Modify</th>
              )}
            </tr>
          </thead>
          <tbody>
            {subApps.map((row: any, row_idx: any) => {
              return (<tr key={row_idx}>
                {getTableCols().map((col, col_idx) => {
                  return editCell(col, row_idx, col_idx);
                })}
                {editStatus && (
                  <td style={{ textAlign: "center" }}>
                    <Link to={'#'}>
                      <span
                        onClick={() => {
                          dispatch(popUpModalActions.onUpdateShow(row));
                        }}
                      >
                        <AiFillEdit />
                        Update
                      </span>
                    </Link>
                    <br />
                    <Link to={`#`} style={{ color: "#e31010" }}>
                      <span
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() => {
                          dispatch(popUpModalActions.onDeleteShow(row));
                        }}
                      >
                        <AiFillDelete />
                        Delete
                      </span>
                    </Link>
                  </td>
                )}
              </tr>)
            })}
          </tbody>
        </Table>

      </div>

    </Container>

  </>
}

export default ManageSubApps;

export async function manageSubAppLoader() {
  const response = await fetch(`${API}/subApps`);
  if (!response.ok) {
    throw json({
      message: 'Could not fetch Sub Apps!'
    }, { status: 500 })
  } else {
    const resData = await response.json();
    return resData;
  }

};