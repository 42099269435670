// src/features/notification/notificationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VariantType } from 'notistack';

interface Notification {
  message: string;
  variant: VariantType;
}

interface NotificationState {
  queue: Notification[];
}

const initialState: NotificationState = {
  queue: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    enqueueNotification: (state, action: PayloadAction<Notification>) => {
      state.queue.push(action.payload);
    },
    clearNotifications: (state) => {
      state.queue = [];
    },
  },
});

export const notificationActions = notificationSlice.actions;

export default notificationSlice;